import fb from "../../assets/social/fb.svg";
import youtube from "../../assets/social/youtube.svg";
import insta from "../../assets/social/insta.svg";
import linkedin from "../../assets/social/linkedin.svg";
import spotify from "../../assets/social/logo-spotify.svg";

export const SocialIcon = () => {
  return (
    <div className="ac_social_icon">
      <a
        href="https://www.youtube.com/channel/UCfAE7c_2Bc22r6HFjVmEYsw"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="minhak" src={youtube} className="ac_img_1" />
      </a>
      <a
        href="https://www.facebook.com/VolcaniInstitute"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="minhak" src={fb} className="ac_img_1" />
      </a>
      <a
        href="https://www.instagram.com/volcani_institute/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="minhak" src={insta} className="ac_img_1" />
      </a>
      <a
        href="https://www.linkedin.com/company/11501563/admin/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="minhak" src={linkedin} className="ac_img_1" />
      </a>
      <a
        href="https://open.spotify.com/show/0qFZqkhtqVvAEWBxsKlr9O"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="spotify" src={spotify} className="ac_img_1" />
        <span>חפשו אותנו</span>
      </a>
    </div>
  );
};
