import React  from 'react';

// regx validation 
export const  regxValidation = ( o ) => {
    if ( o.val === '' ) {
        return false;
    } else {
        if (o.expression.test(o.val)) {
            return true;
        } else {
            return false;
        }
    }
}