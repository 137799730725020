import "./App.css";
import WebFont from "webfontloader";
import ReactPixel from "react-facebook-pixel";
import React, { useEffect } from "react";

import imgRow1 from "./assets/img/main.jpg";
import imgRow1_mobile from "./assets/img/maon-mob.jpg";
import imgRow2 from "./assets/img/Minhal_logo_hebrew_round_low.png";
import bgDesk from "./assets/img/desk.png";
import picto from "./assets/img/picto.svg";
import { Form } from "./Components/Forms/Forms";
import { Footer } from "./Components/Footer/Footer";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Rubik"],
      },
    });

    // ReactPixel.init("595004557268211");
    ReactPixel.init("407140291221325");
    ReactPixel.pageView();
  }, []);

  return (
    // <div className="ac_home_page" style={{backgroundImage: `url(${process.env.PUBLIC_URL + bgDesk})`,backgroundSize: '100% auto',
    // backgroundPosition: '0 0'}}>
    <div className="ac_home_page">
      <div className="ac_row_1">
        <img alt="minhak" src={imgRow1} className="ac_desktop" />
        <img alt="minhak" src={imgRow1_mobile} className="ac_mobile" />
      </div>
      <div className="ac_row_2">
        <div className="ac_row_2_1">
          <img alt="minhak" src={imgRow2} />
          <span className="text_ac_row_2_1">28.2</span>
        </div>
        <div className="ac_row_2_2">
          <span className="ac_row_2_2_1">&nbsp; יום פתוח </span>{" "}
          <span className="ac-blue">&nbsp; און ליין </span>
          {/* <img alt="picto" src={picto} /> */}
        </div>
        <div className="ac_row_2_3">
          <span className="ac_row_2_3_1">במכון וולקני</span>
        </div>
        <div className="ac_row_2_4">
          <div className="ac_row_2_4_block">
            <span className="ac_row_2_4_1">8 הרצאות</span>
            <span className="ac_row_2_4_2">
              {" "}
              <svg
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                class="svg-left"
              >
                <path
                  fill="currentColor"
                  d="M349.5 475.5l-211.1-211c-4.7-4.7-4.7-12.3 0-17l211.1-211c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L178.1 256l195.5 195.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.6-12.3 4.6-17-.1zm-111 0l7.1-7.1c4.7-4.7 4.7-12.3 0-17L50.1 256 245.5 60.5c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0l-211.1 211c-4.7 4.7-4.7 12.3 0 17l211.1 211c4.8 4.8 12.4 4.8 17.1.1z"
                  class=""
                ></path>
              </svg>
            </span>
            <span className="ac_row_2_4_3">{"מגוון אפשרויות "}</span>
          </div>
        </div>
        <div className="ac_row_2_5">
          <span className="ac_row_2_5_1">
            בואו לשמוע על מחקרים חדשניים
            <br />
            לתואר שני, שלישי ופוסטדוקטורט
            <br />
            <span className="ac_strong_color">
              לומדים באוניברסיטה, חוקרים בוולקני
            </span>
          </span>
        </div>
        <div className="ac_row_2_6">
          <Form />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
