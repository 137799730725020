import { useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import $ from "jquery";
import gtag, { install } from "ga-gtag";
import { useForm } from "react-hook-form";
import { regxValidation } from "../../utility";
import "./Form.css";

export const Form = (props) => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const utmMedium = urlParams.get("utm_medium");
  const utmSource = urlParams.get("utm_source");
  const utm_campaign = urlParams.get("utm_campaign");
  const [hidnInput, setHidnInput] = useState("");
  const GetHiddenInput = () => {
    var $hidden_input = document.querySelector("input.lm_serfer").value;

    setHidnInput($hidden_input);
    return hidnInput;
  };

  useEffect(() => {
    setTimeout(function () {
      GetHiddenInput();
    }, 2000);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data) => {
    const dataSend = {
      firstname: data?.firstname,
      lm_serfer: hidnInput,
      lastname: data?.lastname,
      phone: data?.phone,
      email: data?.email,
      media: utmSource,
      utm_campaign: utm_campaign,
      utm_medium: utmMedium,
      agree: data?.agree ? "כן" : "לא",
    };

    var error = false;
    if (dataSend.firstname === "") {
      alert("נא להכניס את השם הפרטי");
      error = true;
    }
    if (dataSend.lastname === "") {
      alert("נא להכניס את שם משפחה");
      error = true;
    }
    if (dataSend.phone === "") {
      alert("נא להכניס את הטלפון הנייד");
      error = true;
    } else {
      const validPhone = regxValidation({
        val: dataSend.phone,
        expression: /^[0-9]{9,11}$/,
      });
      if (validPhone != true) {
        alert("יש להכניס מספר טלפון תקין");
        error = true;
      }
    }

    console.log(dataSend);
    if (dataSend.email === "") {
      alert('נא להכניס את הדוא"ל');
      error = true;
    } else {
      const validEmail = regxValidation({
        val: dataSend.email,
        expression:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      });
      if (validEmail != true) {
        alert('נא להכניס דוא"ל חוקי');
        error = true;
      }
    }

    // console.log(dataSend.lm_serfer)

    if (!error) {
      // console.log('dataSend:', dataSend);
      await fetch("./server/index.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataSend),
      });

      // gtag('event', 'conversion', {'send_to': 'AW-814221647/3CIsCLTIwpMDEM-SoIQD'});
      gtag("event", "conversion", {
        send_to: "AW-10871778290/rVcQCKbo44oYEPLXiMAo",
      });

      // ReactPixel.track("LEAD_VOLCANI");
      ReactPixel.track("volcani23");
      setIsSubmitted(true);
    }
  };

  return (
    <div className={`ac-form-section `}>
      {isSubmitted ? (
        <p>תודה על הרשמתך, יצרו איתך קשר בהקדם</p>
      ) : (
        <>
          <p>לפרטים נוספים ולהרשמה, מלאו פרטים</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div className="input">
                <input
                  name="lm_serfer"
                  class="lm_serfer"
                  type="hidden"
                  {...register("lm_serfer")}
                />
                <input
                  name="lastname"
                  placeholder="שם משפחה"
                  {...register("lastname")}
                  className={errors.lastname?.message ? "invalid" : ""}
                />
                {errors.lastname?.message && (
                  <span>{errors.lastname?.message}</span>
                )}
              </div>
              <div className="input">
                <input
                  placeholder="שם פרטי"
                  name="firstname"
                  {...register("firstname")}
                  className={errors.firstname?.message ? "invalid" : ""}
                />
                {errors.firstname?.message && (
                  <span>{errors.firstname?.message}</span>
                )}
              </div>

              <div className="input">
                <input
                  placeholder="טלפון"
                  className={errors.phone?.message ? "invalid" : ""}
                  type="number"
                  name="phone"
                  {...register("phone")}
                />
                {errors.phone?.message && <span>{errors.phone?.message}</span>}
              </div>
              <div className="input">
                <input
                  name="email"
                  placeholder={`דוא"ל`}
                  className={errors.email?.message ? "invalid" : ""}
                  {...register("email")}
                />
                {errors.email?.message && <span>{errors.email?.message}</span>}
              </div>

              <div className="input_final">
                <div className="input input-checkbox">
                  <input {...register("agree")} name="agree" type="checkbox" />
                  מעת לעת-SMS אני מאשר/ת קבלת עדכונים ב
                </div>
                <button>{isSubmitting ? "שולח.." : "שלחו"}</button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
