// import imgRow1 from "../../assets/img/Group36.png";
// import imgRow2 from "../../assets/img/Group37.png";
import imgRow1 from "../../assets/img/microscope.svg";
import imgRow2 from "../../assets/img/parent.svg";
import { SocialIcon } from "../Social/Social";
import "./Footer.css";
export const Footer = () => {
  return (
    <div className="ac_footer_page">
      <div className="ac_row_1_footer">
        <SocialIcon />
      </div>
      <div className="ac_row_2_footer">
        <a
          href="https://www.volcaniinstitute.info/%D7%9E%D7%97%D7%A7%D7%A8"
          target={"_blank"}
          rel="noopener"
        >
          <div className="ac-circle ac-circle-1">
            <img alt="minhak" src={imgRow1} className="ac_img_1" />
            <span>המעבדה</span>
          </div>
        </a>
        <a
          href="https://www.volcaniinstitute.info/team"
          target={"_blank"}
          rel="noopener"
        >
          <div className="ac-circle ac-circle-2">
            <img alt="minhak" src={imgRow2} className="ac_img_2" />
            <span>מנחה למחקר</span>
          </div>
        </a>
      </div>
    </div>
  );
};
